<template>
  <ValidationObserver
    ref="couponForm"
    v-slot="{ handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('rewards.coupon_no')"
        rules="required"
      >
        <b-form-group
          :label="`${$t('rewards.coupon_no')} *`"
          :label-for="`numberOfCoupon-${value.id}`"
        >
          <b-form-input
            :id="`numberOfCoupon-${value.id}`"
            v-model="couponForm.numberOfCoupon"
            type="number"
            :state="errors[0] ? false : null"
            :readonly="readonly"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('rewards.use_point')"
        rules="required"
      >
        <b-form-group
          :label="`${$t('rewards.use_point')} *`"
          :label-for="`use_point-${value.id}`"
        >
          <b-input-group append="คะแนน">
            <b-form-input
              :id="`use_point-${value.id}`"
              v-model="couponForm.useRewardPoint"
              type="number"
              :state="errors[0] ? false : null"
              :readonly="readonly"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </ValidationProvider>
      <b-form-group
        :label="$t('fields.status')"
        :label-for="`status-${value.id}`"
      >
        <b-form-radio-group
          :id="`status-${value.id}`"
          v-model="couponForm.active"
          name="status"
          :disabled="readonly"
        >
          <b-form-radio :value="true">
            {{$t('utils.status.enable')}}
          </b-form-radio>
          <b-form-radio :value="false">
            {{$t('utils.status.disable')}}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <div class="text-right">
        <b-overlay
          :show="isLoading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            :disabled="readonly"
          >
            {{ $t('buttons.confirm')}}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'CouponForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      couponForm: {
        id: null,
        useRewardPoint: 0,
        numberOfCoupon: 0,
        active: false,
      },
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    setData() {
      if (this.value && this.value.id) {
        const data = JSON.parse(JSON.stringify(this.value))
        this.couponForm = { ...this.couponForm, ...data }
      } else {
        this.couponForm = {
          id: null,
          useRewardPoint: 0,
          numberOfCoupon: 0,
          active: false,
        }
      }
    },
    onSubmit() {
      this.$emit('submit', this.couponForm)
    },
  },
}
</script>
